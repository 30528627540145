
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import FormsReportTypes from '@/components/FormsReportTypes.vue';

export default defineComponent({
    components: { FormsReportTypes },
    mixins: [Base],
    emits: ['reload'],
    data(): any {
        return {
            item: undefined as any,
        };
    },
    mounted(): void {
        this.getItem(this.$route.params.id);
    },
    methods: {
        getItem(id: string): void {
            this.get(`product-categories/${id}`).then(({ data }: any) => {
                this.item = data.data;
                (this.$refs.form as any).setValues(this.item);
            });
        },
        saveItem(): void {
            this.put(`product-categories/${this.$route.params.id}`, {
                ...this.item,
                name: this.item.translations.name,
            }).then(() => {
                this.$router.push({ name: 'products.categories.list' });
                this.$emit('reload');
            });
        },
    },
});
